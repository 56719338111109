exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".src-components-elements-Hero-Hero__hero--1PfO0 {\n  position: relative;\n  width: 100%;\n  height: 100%;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n              -ms-flex-pack: center;\n          justify-content: center;\n}\n\n  .src-components-elements-Hero-Hero__hero__img--3pkI6 {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    -o-object-fit: cover;\n       object-fit: cover;\n    z-index: -1;\n  }\n\n  .src-components-elements-Hero-Hero__hero__btn--2KYXH {\n    position: absolute;\n    color: var(--color-gold);\n    background: none;\n    border: none;\n    padding: 10px 20px;\n    bottom: 30vh;\n    font-family: var(--font-courier);\n    font-size: 38.4px;\n    font-size: 2.4rem;\n    font-weight: bold;\n    -webkit-animation: src-components-elements-Hero-Hero__fadein--3b5CE 8s;\n            animation: src-components-elements-Hero-Hero__fadein--3b5CE 8s;\n  }\n\n  .src-components-elements-Hero-Hero__hero--1PfO0.src-components-elements-Hero-Hero__has-gradient--2Z7Zp {\n    background-image: -webkit-gradient(\n      linear,\n      left top, left bottom,\n      from(rgba(245, 246, 252, 0.52)),\n      to(rgba(117, 19, 93, 0.73)));\n    background-image: -o-linear-gradient(\n      top,\n      rgba(245, 246, 252, 0.52),\n      rgba(117, 19, 93, 0.73));\n    background-image: linear-gradient(\n      to bottom,\n      rgba(245, 246, 252, 0.52),\n      rgba(117, 19, 93, 0.73));\n  }\n\n@-webkit-keyframes src-components-elements-Hero-Hero__fadein--3b5CE {\n  0% { opacity: 0; }\n  50% { opacity: 0; }\n  100% { opacity: 1; }\n}\n\n@keyframes src-components-elements-Hero-Hero__fadein--3b5CE {\n  0% { opacity: 0; }\n  50% { opacity: 0; }\n  100% { opacity: 1; }\n}\n", ""]);
// Exports
exports.locals = {
	"hero": "src-components-elements-Hero-Hero__hero--1PfO0",
	"hero__img": "src-components-elements-Hero-Hero__hero__img--3pkI6",
	"hero__btn": "src-components-elements-Hero-Hero__hero__btn--2KYXH",
	"fadein": "src-components-elements-Hero-Hero__fadein--3b5CE",
	"has-gradient": "src-components-elements-Hero-Hero__has-gradient--2Z7Zp"
};